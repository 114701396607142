import React from 'react'

/**
* @author
* @function AccountSettings
**/

export const AccountSettings = (props) => {
  return(
    <div>AccountSettings</div>
   )

 }